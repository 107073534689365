import { GameFog, GamePlaceUnitsState, GamePlayerType, GamePrivacy, GameRulesWinCondition, GameSpecialSeats, GameUnseenActionsState } from "../enum/game";
import GameState from "../enum/gamestate";
import getItemById from "../util/getItemById";
import IGameMode from "./game/modes/igamemode";
import GameModeStandard from "./game/modes/standard";
import GameModeTeams from "./game/modes/team";
import defaultRules from "./rules";
import GameModeAssassin from "./game/modes/assassin";
import { Action, ActionType, AttackAction, BonusCalculation, REPLAY_IGNORE_ACTION_TYPES, ChatActions } from "./game/action";
import { getBestCardsSetIndexesIfExists } from "./cardsets";
import { ApiCard, ApiCountry, ApiGame, ApiPlayer, ApiSeat, GameSeatStats } from "../types/apigame";
import GameModeCapitals from "./game/modes/capitals";
import GameModeCatan from "./game/modes/catan";
import { MapBorderType } from "../enum/map";
import { Rules } from "../types/rule";
import GameModePercent from "./game/modes/percent";
import { ApiMapBorder, ApiMapCard, ApiMapContinent, ApiMapCountry } from "../types/apimap";
import { CountryId } from "../types/map";
import IGameFog from "./game/fog/igamefog";
import GameFogHeavy from "./game/fog/heavy";
import GameFogLight from "./game/fog/light";
import GameFogNone from "./game/fog/none";
import defaultColors, { COLOR_NEUTRAL, ColorId, CountryColor, isValidPlayerColor } from "../types/color";

const MIN_UNITS_TO_PLACE = 3;

const MIN_NUDGE_DELAY_HUMAN = 60 * 60 * 1000;
const MIN_NUDGE_DELAY_BOT = 60 * 1000;

export const ACTION_TYPES_TO_IGNORE_FOR_UNDO = [
    ActionType.Chat,
    ActionType.Hidden,
];

export default class Game {
    public fog: IGameFog;
    protected _gameMode: IGameMode;

    constructor(public game: ApiGame) {
        const rules = this.getRules();

        if (rules.winCondition === GameRulesWinCondition.Assassin) {
            this._gameMode = new GameModeAssassin(this);
        } else if (rules.winCondition === GameRulesWinCondition.Capitals) {
            this._gameMode = new GameModeCapitals(this);
        } else if (rules.winCondition === GameRulesWinCondition.Catan) {
            this._gameMode = new GameModeCatan(this);
        } else if (rules.winCondition === GameRulesWinCondition.Percent) {
            this._gameMode = new GameModePercent(this);
        } else if (rules.teams > 1) {
            this._gameMode = new GameModeTeams(this);
        } else {
            this._gameMode = new GameModeStandard(this);
        }

        if (rules.fog === GameFog.Heavy) {
            this.fog = new GameFogHeavy(this);
        } else if (rules.fog === GameFog.Light) {
            this.fog = new GameFogLight(this);
        } else {
            this.fog = new GameFogNone(this);
        }
    }

    public get gameMode(): IGameMode { return this._gameMode; }
    public get name(): string { return this.game.name ?? `Game #${this.game.id}`; }

    /**
     * Returns true if cards are a set. There must be 3 any only 3 cards in
     * the array, and the cards must all match or be unique (including wilds)
     * @param cards
     * @returns
     */
    areCardsASet(cards: ApiCard[]): boolean {
        if (cards.length !== 3) {
            throw new Error('You can must turn in only 3 cards');
        }

        const wilds = cards.filter(
            card => {
                const mapCard = this.getMapCard(card.cardId);
                return mapCard.isWild;
            },
        );

        /**
         * If the number of non-wild cards are less than 2, it must be a set,
         * e.g.:
         * (Card A) (Wild)   (Wild)
         * (Card B) (Card B) (Wild) - 3 of a kind
         * (Card A) (Card C) (Wild) - all 3 unique
         */
        if (wilds.length > 0) {
            return true;
        }

        const isThreeOfAKind = cards[0].cardId === cards[1].cardId
            && cards[1].cardId === cards[2].cardId;

        const areThreeUnique = cards[0].cardId !== cards[1].cardId
            && cards[1].cardId !== cards[2].cardId
            && cards[0].cardId !== cards[2].cardId;

        return isThreeOfAKind || areThreeUnique;
    }

    areFortifiesValid(
        seatNumber: number,
        fortifies: any[],
    ): boolean {
        const rules = this.getRules();
        if (
            rules.maxFortifies &&
            fortifies.length > rules.maxFortifies
        ) {
            return false;
        }

        const tmpCountries = this.game.countries.map(
            country => Object.assign({}, country),
        );

        for (let i = 0; i < fortifies.length; ++i) {
            const fortify = fortifies[i];

            const fromCountry = getItemById(
                tmpCountries,
                fortify.fromCountryId,
                'countryId',
            );

            const toCountry = getItemById(
                tmpCountries,
                fortify.toCountryId,
                'countryId',
            );

            const units = fortify.units;

            if (
                !this.hasBorder(
                    fortify.fromCountryId,
                    fortify.toCountryId,
                )
            ) {
                return false;
            }

            if (
                units < 0
                || units > fromCountry.units - rules.minUnitsPerTerritory
            ) {
                return false;
            }

            if (
                !this.gameMode.canFortify(
                    seatNumber,
                    fortify.fromCountryId,
                    fortify.toCountryId,
                )
            ) {
                return false;
            }

            const toMapCountry = this.getMapCountry(toCountry.countryId);
            if (toMapCountry.maxUnits > 0 && toCountry.units + units > toMapCountry.maxUnits) {
                return false;
            }

            fromCountry.units -= units;
            toCountry.units += units;
        }

        return true;
    }

    canAttack(
        seatNumber: number,
        fromCountryId: number,
        toCountryId: number,
    ): boolean {
        if (this.game.state !== GameState.Attack) {
            return false;
        }

        const seatObject = this.getSeatObjectBySeatNumber(seatNumber);
        if (!seatObject) {
            return false;
        }

        const fromCountry = this.getCountryById(fromCountryId);
        const toCountry = this.getCountryById(toCountryId);
        if (!fromCountry || !toCountry) {
            return false;
        }

        if (fromCountry.seatNumber !== seatObject.seatNumber || toCountry.seatNumber === seatObject.seatNumber) {
            return false;
        }

        if (!this.hasBorder(fromCountryId, toCountryId)) {
            return false;
        }

        return true;
    }

    canNudge(): boolean {
        if (
            this.game.state === GameState.Created
            || this.game.state === GameState.Complete
        ) {
            return false;
        }

        const now = Date.now();
        const msSinceLastUpdate = now - this.game.lastUpdated;

        const turn = this.game.turn;
        const turnSeatObject = this.getSeatObjectBySeatNumber(turn);
        const isCurrentTurnBot = turnSeatObject.botId != null;

        if (isCurrentTurnBot && msSinceLastUpdate < MIN_NUDGE_DELAY_BOT) {
            return false;
        } else if (!isCurrentTurnBot && msSinceLastUpdate < MIN_NUDGE_DELAY_HUMAN) {
            return false;
        }

        return true;
    }

    canUndoPlaceUnits(userId: number): boolean {
        if (this.game.state !== GameState.Attack) {
            return false;
        }

        const seatObject = this.getSeatObjectByUserId(userId);
        if (this.game.turn !== seatObject?.seatNumber) {
            return false;
        }

        if (this.game.actions.length <= 0) {
            return false;
        }

        for (let i = this.game.actions.length - 1; i >= 0; --i) {
            const action = this.game.actions[i];
            const actionType = action.actionType;

            if (ACTION_TYPES_TO_IGNORE_FOR_UNDO.includes(actionType)) {
                continue;
            }

            return actionType === ActionType.PlaceUnits
                && action.userId === userId;
        }
    }

    canUndoTransferUnits(userId: number): boolean {
        if (this.game.state === GameState.Transfer) {
            return false;
        }

        const seatObject = this.getSeatObjectByUserId(userId);
        if (this.game.turn !== seatObject?.seatNumber) {
            return false;
        }

        if (this.game.actions.length <= 0) {
            return false;
        }

        for (let i = this.game.actions.length - 1; i >= 0; --i) {
            const action = this.game.actions[i];
            const actionType = action.actionType;

            if (ACTION_TYPES_TO_IGNORE_FOR_UNDO.includes(actionType)) {
                continue;
            }

            if (actionType !== ActionType.TransferUnits) {
                return false;
            }

            return action.data.seatNumber === seatObject.seatNumber;
        }
    }

    checkCanUserAddBot(userId: number): void {
        if (this.game.state !== GameState.Created) {
            throw new Error('Cannot add bot after game is started')
        }

        if (this.game.creatorId !== userId) {
            throw new Error('Only creator can add bot');
        }
    }

    checkCanUserInvite(userId: number): void {
        if (this.game.state !== GameState.Created) {
            throw new Error('Cannot invite after game has started');
        }

        const seatObject = this.getSeatObjectByUserId(userId);
        if (!seatObject) {
            throw new Error('Only users in game can invite');
        }

        if (
            this.game.privacy !== GamePrivacy.AnyoneCanJoin
            && userId !== this.game.creatorId
        ) {
            throw new Error('Only game creator can invite');
        }
    }

    deepCopyDataModel(ignoreHistory: boolean = false): ApiGame {
        const deepCopy: ApiGame = {} as any;

        for (const key in this.game) {
            if (key === 'actions' && ignoreHistory) {
                continue;
            }

            deepCopy[key] = structuredClone(this.game[key]);
        }

        return deepCopy;
    }

    countryCanAttackSomeone(countryId: number): boolean {
        const rules = this.getRules();
        const fromCountry = this.getCountryById(countryId);
        if (!fromCountry || fromCountry.units <= rules.minUnitsPerTerritory) {
            return false;
        }

        for (const border of this.game.map.data.borders) {
            if (
                border.from !== countryId
                && border.to !== countryId
            ) {
                continue;
            }

            let toCountryId = border.from === countryId
                ? border.to
                : border.from;

            const toCountry = this.getCountryById(toCountryId);

            if (!this.isCountryActive(toCountry)) {
                continue;
            }

            if (fromCountry.seatNumber !== toCountry.seatNumber) {
                return true;
            }
        }

        return false;
    }

    countryHasAlliedNeighbor(countryId: number): boolean {
        const fromCountry = this.getCountryById(countryId);
        if (!fromCountry) {
            return false;
        }

        for (const border of this.game.map.data.borders) {
            if (
                border.from !== countryId
                && border.to !== countryId
            ) {
                continue;
            }

            let toCountryId = border.from === countryId
                ? border.to
                : border.from;

            const toCountry = this.getCountryById(toCountryId);
            if (this.gameMode.areAllies(fromCountry.seatNumber, toCountry.seatNumber)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if a seat owns a continent.
     *
     * Special check for invisible countries. Seat must own at least one
     * (no bonus for continents where all seats are gone)
     */
    doesSeatOwnContinent(seatNumber: number, continentId: number): boolean {
        const continent = this.getContinentDataById(continentId);

        // assume it is not owned
        let isOwned = false;

        for (const countryId of continent.countries) {
            const country = this.getCountryById(countryId);

            if (!this.isCountryActive(country)) {
                continue;
            }

            if (country.seatNumber === seatNumber) {
                isOwned = true;
            } else {
                isOwned = false;
                break;
            }
        }

        return isOwned;
    }

    getAttackableNeighbors(countryId: CountryId): ApiCountry[] {
        const country = this.getCountryById(countryId);

        return this
            .getNeighbors(country.countryId)
            .filter((neighbor) => neighbor.seatNumber !== country.seatNumber);
    }

    getAvailableColor(): ColorId {
        const usedColors = new Set<ColorId>();

        for (const seatObject of this.game.seats) {
            if (seatObject.color != null) {
                usedColors.add(seatObject.color);
            }
        }

        for (const color of defaultColors) {
            if (isValidPlayerColor(color.id) && !usedColors.has(color.id)) {
                return color.id;
            }
        }
    }

    getCardsForSeat(seatNumber: number): ApiCard[] {
        return this.game.cards.filter(
            card => card.seatNumber === seatNumber && !card.isDiscarded,
        );
    }

    getColorForSeat(seatNumber: number): CountryColor {
        if (seatNumber < 0) {
            return COLOR_NEUTRAL;
        }

        const seatObject = this.getSeatObjectBySeatNumber(seatNumber);

        const color = defaultColors.get(seatObject.color);

        return color;
    }

    getCountryById(countryId: number): ApiCountry {
        return getItemById(this.game.countries, countryId, 'countryId');
    }

    getContinentDataById(continentId: number): ApiMapContinent {
        return getItemById(this.game.map.data.continents, continentId, 'id');
    }

    getOwnedContinentsForSeat(seatNumber: number): ApiMapContinent[] {
        return this.game.map.data.continents
            .filter(
                (continent) => this.doesSeatOwnContinent(
                    seatNumber,
                    continent.id,
                ),
            );
    }

    getPlayerByUserId(userId: number): ApiPlayer {
        return this.game.players.find(
            (player) => player.id === userId,
        );
    }

    getSeatObjectForPlayer(
        playerId: number,
        playerType: GamePlayerType = GamePlayerType.User,
    ): ApiSeat {
        switch (playerType) {
            case GamePlayerType.User:
                return this.getSeatObjectByUserId(playerId);

            case GamePlayerType.Bot:
                return this.getSeatObjectByBotId(playerId);

            default:
                throw new Error('Unknown player type');
        }
    }

    getSeatObjectById(id: number): ApiSeat {
        return getItemById(this.game.seats, id);
    }

    getSeatObjectBySeatNumber(seatNumber: number): ApiSeat {
        return getItemById(this.game.seats, seatNumber, 'seatNumber');
    }

    getSeatObjectByBotId(botId: number): ApiSeat {
        return getItemById(this.game.seats, botId, 'botId');
    }

    getSeatObjectByUserId(userId: number): ApiSeat {
        return getItemById(this.game.seats, userId, 'userId');
    }

    getSeatObjectsByTeam(team: number): ApiSeat[] {
        return this.game.seats.filter((seatObject) => seatObject.team === team);
    }

    getAllContinentsDataByCountryId(countryId: number): ApiMapContinent[] {
        const continentsData: ApiMapContinent[] = [];
        for (const continent of this.game.map.data.continents) {
            if (continent.countries.includes(countryId)) {
                continentsData.push(continent);
            }
        }
        return continentsData;
    }

    getCountriesForSeat(seatNumber: number): ApiCountry[] {
        return this.game.countries.filter(
            country => country.seatNumber === seatNumber,
        );
    }

    getMapCard(cardId: number): ApiMapCard {
        return getItemById(this.game.map.data.cards, cardId);
    }

    getMapCountry(countryId: number): ApiMapCountry {
        return getItemById(this.game.map.data.countries, countryId);
    }

    getMinUnitBonus(): number {
        return MIN_UNITS_TO_PLACE;
    }

    getNeighbors(countryId: number): ApiCountry[] {
        return this.game.countries
            .filter(
                (country: ApiCountry) => this.hasBorder(countryId, country.countryId),
            );
    }

    getContinentNeighbors(continentId: number): number[] {
        const neighborContinentsSet = new Set<number>();
        const continent = this.getContinentDataById(continentId);
        for (const countryId of continent.countries) {
            const neighborCountries = this.getNeighbors(countryId);
            for (const neighborCountry of neighborCountries) {
                const neighborContinents = this.getAllContinentsDataByCountryId(neighborCountry.countryId);
                for (const neighborContinent of neighborContinents) {
                    if (neighborContinent.id !== continentId) {
                        neighborContinentsSet.add(neighborContinent.id);
                    }
                }
            }
        }
        return [...neighborContinentsSet].sort((a, b) => a - b);
    }

    /**
     * Gets the seat of the next turn. Will skip over eliminated players.
     */
    getNextTurn(): number {
        // Get current turn
        let turn = this.game.turn;

        do {
            // Advance turn and wrap around
            if (++turn >= this.game.seats.length) {
                turn = 0;
            }

            const seatObject = this.getSeatObjectBySeatNumber(turn);
            if (!seatObject.accepted) {
                continue;
            }

            // Check if the player has any countries
            const countries = this.game.countries.filter(
                country => country.seatNumber === turn,
            );

            // We're good!
            if (countries.length > 0) {
                break;
            }
            // Prevent infinite loops
        } while (turn !== this.game.turn);

        return turn;
    }

    /**
     * Returns the rules for the game.
     * Game rules override map rules, which override defaults.
     */
    getRules(): Rules {
        return Object.assign(
            {} as Rules,
            defaultRules,
            this.game.map.data?.rules ?? {} as Rules,
            this.game.rules,
        );
    }

    /**
     * Gets the name for a seat. This will also return special
     * values like "Neutral"
     */
    getSeatName(seatNumber: number): string {
        if (seatNumber === GameSpecialSeats.Invisible) {
            return 'The Void';
        } else if (seatNumber === GameSpecialSeats.Fogged) {
            return 'Fog';
        } else if (seatNumber === GameSpecialSeats.Neutral) {
            return 'Neutral';
        } else if (seatNumber === GameSpecialSeats.Blizzard) {
            return 'Blizzard';
        } else if (seatNumber < 0) {
            return 'Unknown';
        }

        const seatObject = this.getSeatObjectBySeatNumber(seatNumber);
        return seatObject.name;
    }

    getSeatStats(): Map<number, GameSeatStats> {
        const rules = this.getRules();
        const seatObjects = this.game.seats;
        const attacks = this.game.actions.filter(
            (action) => action.actionType === ActionType.Attack,
        ) as AttackAction[];

        const seatStats = new Map<number, GameSeatStats>();

        for (const seatObject of seatObjects) {
            seatStats.set(seatObject.seatNumber, {
                attacks: 0,
                defends: 0,
                attackKills: 0,
                attackDeaths: 0,
                defendKills: 0,
                defendDeaths: 0,
                attackDiceRolls: Array(rules.defaultAttackDieFaces).fill(0),
                defendDiceRolls: Array(rules.defaultDefendDieFaces).fill(0),
                seatAttacks: Array(seatObjects.length).fill(0),
                seatDefends: Array(seatObjects.length).fill(0),
            });
        }

        for (const attack of attacks) {
            const data = attack.data;

            const attackerStats = seatStats.get(data.fromSeatNumber);
            attackerStats.attacks++;
            attackerStats.attackKills += data.result.defenderDeaths;
            attackerStats.attackDeaths += data.result.attackerDeaths;

            for (const die of data.result.attackerRolls) {
                attackerStats.attackDiceRolls[die - 1]++;
            }

            if (data.toSeatNumber !== -1) {
                attackerStats.seatAttacks[data.toSeatNumber]++;
                const defenderStats = seatStats.get(data.toSeatNumber);
                defenderStats.defends++;
                defenderStats.defendKills += data.result.attackerDeaths;
                defenderStats.defendDeaths += data.result.defenderDeaths;
                for (const die of data.result.defenderRolls) {
                    defenderStats.defendDiceRolls[die - 1]++;
                }
                defenderStats.seatDefends[data.fromSeatNumber]++;
                seatStats.set(data.toSeatNumber, defenderStats);
            }

            seatStats.set(data.fromSeatNumber, attackerStats);
        }

        return seatStats;
    }

    getUnitBonus(cardsTurnedIn?: number): number {
        const rules = this.getRules();

        if (cardsTurnedIn == null) {
            cardsTurnedIn = this.game.cardState;
        }

        const cardBonusRules = rules.cardBonus;
        const setValues = [cardBonusRules.base];
        if (!Array.isArray(cardBonusRules.increment)) {
            console.log('cardBonusRules is', cardBonusRules);
            throw new Error('cardBonus increment must be an array');
        }
        for (const increment of cardBonusRules.increment) {
            const lastValue = setValues.pop();
            const nextValue = lastValue + increment;
            setValues.push(lastValue, nextValue);
        }
        if (cardsTurnedIn < setValues.length) {
            return setValues[cardsTurnedIn];
        }
        if (typeof cardBonusRules.loop === 'boolean') {
            if (cardBonusRules.loop) {
                return setValues[cardsTurnedIn % setValues.length];
            }
            const lastValue = setValues[setValues.length - 1];
            const lastIncrement = cardBonusRules.increment[cardBonusRules.increment.length - 1];
            return lastValue + (lastIncrement * (cardsTurnedIn - setValues.length + 1));
        }
        // loop last X
        const loopIndex = ((cardsTurnedIn) - setValues.length) % cardBonusRules.loop
        return setValues[setValues.length - 1 - cardBonusRules.loop + loopIndex];
    }

    getCountryBonus(seatNumber: number): number {
        const rules = this.getRules();
        const countries = this.getCountriesForSeat(seatNumber);

        const countryBonus = Math.floor(
            countries.length / rules.countriesPerUnitBonus,
        );

        return Math.max(
            this.getMinUnitBonus(),
            countryBonus,
        );
    }

    getContinentBonus(seatNumber: number): number {
        let bonus = 0;

        const continentsOwned = this.getOwnedContinentsForSeat(seatNumber);
        for (const continent of continentsOwned) {
            bonus += Number(continent.bonus || 0);
        }

        return bonus;
    }

    getBonusCalculationOnTurnStart(seat?: number): BonusCalculation {
        return this.gameMode.getBonusCalculationOnTurnStart(seat);
    }

    getChatterNameForChatActions(action: ChatActions): string {
        const seatNumber = this.getSeatNumberForChatActions(action);

        if (seatNumber != null) {
            const seatObject = this.getSeatObjectBySeatNumber(seatNumber);
            return seatObject.name;
        }

        const player = this.game.players.find(
            (playerIterator) => playerIterator.id === action.userId,
        );

        return player?.name ?? 'Unknown';
    }

    getChatterAvatarForChatActions(action: ChatActions): string {
        const seatNumber = this.getSeatNumberForChatActions(action);

        if (seatNumber != null) {
            const seatObject = this.getSeatObjectBySeatNumber(seatNumber);
            return seatObject?.user?.avatar;
        }

        const player = this.game.players.find(
            (playerIterator) => playerIterator.id === action.userId,
        );

        return player?.avatar;
    }

    getSeatNumberForChatActions(action: ChatActions): number {
        let seatNumber: number;

        if (action.actionType === ActionType.Chat) {
            seatNumber = action.data.seatNumber;
        } else if (action.actionType === ActionType.Nudge) {
            seatNumber = action.data.fromSeatNumber;
        }

        if (!seatNumber) {
            const seatObject = this.getSeatObjectByUserId(action.userId);
            seatNumber = seatObject?.seatNumber;
        }

        return seatNumber;
    }

    getUnitsToAwardOnTurnStart(seat?: number): number {
        const bonusCalculation = this.getBonusCalculationOnTurnStart(seat);

        return Math.max(0,
            bonusCalculation.countryBonus
            + bonusCalculation.continentBonus
            + bonusCalculation.cardSetBonus
            + bonusCalculation.eliminationBonus
            + (bonusCalculation.capitalBonus || 0)
        );
    }

    getUnitsToPlace(seatNumber?: number): number {
        if (!(seatNumber >= 0)) {
            seatNumber = this.game.turn;
        }

        const userSeatObject = this.getSeatObjectBySeatNumber(seatNumber);
        const seatBonus = userSeatObject.unitBonus;

        return this.getUnitsToAwardOnTurnStart(seatNumber) + seatBonus;
    }

    getUserIdBySeat(seatNumber: number): number | null {
        const seatObject = this.getSeatObjectBySeatNumber(seatNumber);
        if (!seatObject) {
            return null;
        }

        return seatObject.userId || seatObject.user?.id || null;
    }

    getCardsSetIfExists(seatNumber: number): ApiCard[] | null {
        const gameCards = this.getCardsForSeat(seatNumber);

        // denormalize card data so we have a cardId and isWild in one object
        const cards = gameCards.map((card) => ({ isWild: this.getMapCard(card.cardId).isWild, cardId: card.cardId }));

        const bestIndexes = getBestCardsSetIndexesIfExists(cards);

        if (!bestIndexes) {
            return null;
        }
        return [
            gameCards[bestIndexes[0]],
            gameCards[bestIndexes[1]],
            gameCards[bestIndexes[2]],
        ];
    }

    getUnseenActions(): Action[] {
        const actions = this.game.actions;
        const lastActionId = actions[actions.length - 1].id;
        const lastSeenAction = this.game.lastSeenAction;

        if (!lastSeenAction || lastSeenAction >= lastActionId) {
            return [];
        }

        const lastSeenActionIndex = actions.findLastIndex(
            (action) => action.id === lastSeenAction,
        );

        return actions.slice(lastSeenActionIndex + 1);
    }

    getUnseenActionsState(
        unseenActions: Action[],
    ): GameUnseenActionsState {
        let state: GameUnseenActionsState = GameUnseenActionsState.None;

        for (const action of unseenActions) {
            const actionType = action.actionType;

            if (REPLAY_IGNORE_ACTION_TYPES.includes(actionType)) {
                continue;
            } else if (actionType === ActionType.Chat) {
                state = GameUnseenActionsState.ChatsOnly;
            } else {
                state = GameUnseenActionsState.ReplayAvailable;

                break;
            }
        }

        return state;
    }

    hasBorder(fromCountryId: number, toCountryId: number): boolean {
        const fromCountry = this.getCountryById(fromCountryId);
        const toCountry = this.getCountryById(toCountryId);

        if (
            !this.isCountryActive(fromCountry)
            || !this.isCountryActive(toCountry)
        ) {
            return false;
        }

        return this.game.map.data.borders
            .some(
                (border: ApiMapBorder) => border.from === fromCountryId && border.to === toCountryId
                    || (border.type !== MapBorderType.ONE_WAY &&
                        border.to === fromCountryId && border.from === toCountryId)
            );
    }

    isBlindAtOnceUnitPlacement(): boolean {
        return this.game.state === GameState.PlaceUnits
            && this.game.subState !== null
            && this.game.subState !== undefined
            && typeof this.game.subState === 'object'
            && this.game.subState.mode === GamePlaceUnitsState.BlindAtOnceUnits;
    }

    public isCreator(userId: number): boolean {
        return this.game.creatorId === userId;
    }

    isCountryActive(country: number | ApiCountry): boolean {
        if (typeof country === 'number') {
            country = this.getCountryById(country);
        }

        return !country.extraData.isInvisible
            && !country.extraData.isBlizzard;
    }

    isCountryAdjacentToSeat(countryId: number, seatNumber: number): boolean {
        return this._gameMode.isCountryAdjacentToSeat(countryId, seatNumber);
    }

    isEmailInvited(email: string): boolean {
        const lowerCaseEmail = email.toLowerCase();

        return !!this.game.invites.find(
            (invite) => invite.invitedUser?.email === lowerCaseEmail,
        );
    }

    isGameOver(countryToExclude?: ApiCountry): boolean {
        return this.gameMode.isGameOver(countryToExclude);
    }

    areOnlyBotsRemaining(seatNumberToExclude?: number): boolean {
        return this.game.seats
            .filter(
                (seatObject) => seatObject.seatNumber !== seatNumberToExclude
                    && seatObject.isAlive
                    && seatObject.accepted
            )
            .every((seatObject) => seatObject.botId);

    }

    isTurn(seatNumber: number): boolean {
        if (this.isBlindAtOnceUnitPlacement()) {
            return this.game.subState.seats.indexOf(seatNumber) === -1;
        }

        if (this.game.turn === seatNumber) {
            return true;
        }

        return false;
    }

    isUserInvited(userId: number): boolean {
        return !!this.game.invites.find(
            (invite) => invite.user?.id === userId,
        );
    }

    mustTurnInCards(): boolean {
        const seatNumber = this.game.turn;
        const cardCount = this.getCardsForSeat(seatNumber).length;
        const rules = this.getRules();

        return this.game.state === GameState.PlaceUnits
            && this.game.subState !== GamePlaceUnitsState.JustUnits
            && rules.forceCardTurninCount > 0
            && cardCount >= rules.forceCardTurninCount;
    }

    willNudgeCauseSurrender(): boolean {
        const rules = this.getRules();
        const deltaTime = Date.now() - this.game.turnStartTime;

        const currentTurnSeatObject = this.getSeatObjectBySeatNumber(this.game.turn);

        return !currentTurnSeatObject.botId
            && rules.maxDaysIdle > 0
            && deltaTime >= 1000 * 60 * 60 * 24 * rules.maxDaysIdle;
    }
};
