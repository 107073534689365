export enum ClientRequests {
    SubscribeGame = 'subscribeGame',
    UnsubscribeGame = 'unsubscribeGame',
    GetGameFullState = 'getGameFullState',
    MarkChatAsRead = 'markChatAsRead',
    GetLastSeenChatActionId = 'getLastSeenChatActionId',
    GetStats = 'getStats',
    SetServerFlags = 'setServerFlags',
    GetWebsocketsStats = 'getWebsocketsStats',
    GetUserProfile = 'getUserProfile',
    Nudge = 'nudge',
    GameCallMethod = 'gameCallMethod',
    GetGames = 'getGames',
    ClearCache = 'clearCache',
    InvitePlayer = 'invitePlayer',
    ValidateMapShortName = 'validateMapShortName',
    PublishMap = 'publishMap',
    UnpublishMap = 'unpublishMap',
    CreateMap = 'createMap',
    SaveMapData = 'saveMapData',
    GetMapSvg = 'getMapSvg',
    UploadMapSvg = 'uploadMapSvg',
    AddBot = 'addBot',
    Attack = 'attack',
    Chat = 'chat',
    CancelGame = 'cancelGame',
    AssignTeams = 'assignTeams',
    SetAvatar = 'setAvatar',
    ChangeName = 'changeName',
    GetServerFlags = 'getServerFlags',
    SetMaxSeats = 'setMaxSeats',
    SetPrivacy = 'setPrivacy',
    SetRules = 'setRules',
    GetAnalytics = 'getAnalytics',
    GetAnalyticsForMonth = 'getAnalyticsForMonth',
    GetUserProfiles = 'getUserProfiles',
    InviteEmail = 'inviteEmail',
    RemoveEmail = 'removeEmail',
    ContinuousAttack = 'continuousAttack',
    CreateGame = 'createGame',
    DeclineInvite = 'declineInvite',
    Fortify = 'fortify',
    Surrender = 'surrender',
    UndoTransferUnits = 'undoTransferUnits',
    UndoPlaceUnits = 'undoPlaceUnits',
    TransferUnits = 'transferUnits',
    GetRandomGameName = 'getRandomGameName',
    SetSlackId = 'setSlackId',
    CheckSlackId = 'checkSlackId',
    GetSubscriptions = 'getSubscriptions',
    SetSlackSubscription = 'setSlackSubscription',
    SetEmailSubscription = 'setEmailSubscription',
    JoinGame = 'joinGame',
    PlaceUnits = 'placeUnits',
    TurnIn = 'turnIn',
    Start = 'start',
};
