import { BonusCalculation } from '../action';
import { ApiCountry, ApiSeat } from "../../../types/apigame";

export type IPathFindResult = {
    countries: ApiCountry[];
    paths: {
        [key: number]: number[];
    };
}

export type CombatStats = {
    dieFaces: number;
    bonus: number;
    maxUnits: number;
};

export enum CombatMode {
    Attack = 0,
    Defend,
};

export default interface IGameMode {
    areAllies(aSeatNumber: number, bSeatNumber: number): boolean;
    canDeploy(seatNumber: number, countryId: number): boolean;
    canFortifyFrom(seatNumber: number, fromCountryId: number): boolean;
    canFortify(seatNumber: number, fromCountryId: number, toCountryId: number): boolean;
    getBonusCalculationOnTurnStart(seat?: number): BonusCalculation;
    getCombatStats(countryId: number, mode: CombatMode): CombatStats;
    getFortifiableCountries(seat: number): any[];
    getGameModeName(): string;
    getGameModeIconName(): string;
    getGameModeDescription(): string;
    getLobbyIcon(seatObject: any): any;
    getNameForSeat(seatNumber: number): string;
    getPlayerListIconForSeat(seatNumber: number, localPlayerSeatNumber?: number): any;
    getPlayerListTagIconForSeat(seatNumber: number): any;
    getShapeIndex(seat: number): number;
    getWinner(): string;
    getWinnerSeats(): ApiSeat[];
    isCountryAdjacentToSeat(countryId: number, seat: number): boolean;
    isDangerousAttack(fromCountry: ApiCountry, toCountry: ApiCountry): boolean;
    isGameOver(countryToExclude?: ApiCountry): boolean;
    fortifyPathFind(fromCountry: ApiCountry, fortifyCount: number): IPathFindResult;
}
